
import { Component, Prop, Vue } from 'vue-property-decorator';

interface InputAttributes {
  id: string;
  label: string;
  labelClass: string;
  formgroupClass: string;
  type: string;
  autocomplete: string;
  state: () => boolean | null;
  errorMessage: string;
  placeholder: string;
  value: string;
  variant: string;
  disabled: boolean;
}

@Component({})
export default class FormInput extends Vue {
  @Prop() inputAttrs!: InputAttributes;

  public updateInput(newValue: string) {
    this.$emit('input', newValue);
  }
}
